.App-logo1 {
  width: 10rem; /* Use em ou rem com um tamanho apropriado */
}

.App-logo {
  width: 5rem; /* Use em ou rem com um tamanho apropriado */
}

.App-logo2 {
  width: 1.5rem; /* Use em ou rem com um tamanho apropriado */
}

.teste{
  font-family: 'Barlow';
  font-weight: 700;
}

.Button {
  font-family: 'Barlow';
  font-weight: 600;
}
/* Increase the specificity */
.Button:disabled {
  color: red;
}

.active {
  color: #000; /* Cor desejada para a aba ativa */
  font-family: 'Barlow';
  font-size: 12px;
  font-weight: 800;
  margin-left: 0.5rem;
  /* Outros estilos desejados para a aba ativa */
}

/* Estilos para o botão fora da rota atual */
.inactive {
  color: #fff; /* Cor desejada para a aba inativa */
  font-family: 'Barlow';
  font-size: 12px;
  font-weight: 800;
  margin-left: 0.2rem;
  /* Outros estilos desejados para a aba inativa */
}