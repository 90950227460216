@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800;900&display=swap');


.Number {
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 60px;              
  text-decoration: none;
  margin-top: 0;
  margin-bottom: -8%;
  color: '#FFF',
}

.Number-info {
  font-family: 'Barlow';
  font-Size: 12px;
  text-decoration: none;
  font-weight: 600;
  margin: 0%
}

.Info-up {
  font-family: 'Barlow';
  font-size: 12px;
  text-decoration: none;
  margin-bottom: 0;
  font-weight: 600
}

.Info-bottom {
  color: #1F0A57;
  font-family: 'Barlow';
  font-size: 10px;
  margin-top: 0;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  margin-left: 2em;
  margin-right: 2em;
}



.Assinar {
  color: '#000';
  font-size: 12px;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 20px;
  background: #FFF;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
}

.Assinar2 {
  font-size: 4rem;
  margin-top: 1rem;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 20px;
  background: #FFF;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
}



/* hide disabled buttons */
.rec.rec-arrow {
  color: #FDEB1A;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.rec.rec-arrow:active {
  color: #FDEB1A
}

.TextoSuperior {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: white;
}

@media (max-width: 1920px) {
/* Estilos padrão para os itens do Carousel */
.custom-carousel .carousel-item {
  /* Seus estilos padrão aqui */
  transition: transform 0.3s ease-in-out; /* Uma transição suave de transformação */
}

/* Estilos quando o mouse está sobre um item do Carousel */
.custom-carousel .carousel-item:hover {
  transform: scale(1.1); /* Aumenta o tamanho em 10% (ou qualquer valor desejado) */
  /* Outras animações ou estilos de mouseover aqui */
}

/* Estilos quando o mouse sai de um item do Carousel */
.custom-carousel .carousel-item:not(:hover) {
  transform: scale(1); /* Retorna ao tamanho normal */
  /* Outras animações ou estilos de mouseout aqui */
}

.div-info-pagamento {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.text-title-valor {
  color: #2091C9 !important;
  font-family: 'Barlow' !important;
  font-weight: 700 !important;
  font-size: 7em !important;
  margin-bottom: 0.4em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 0.55em;

}

.text-sub-valor{
  display: flex;
  justify-content: flex-start;
  color: #2091C9 !important;
  font-family: 'Barlow' !important;
  font-weight: 500 !important;
  font-size: 0.8em !important;
  margin-bottom: 0em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 1em;
}

.text-cifra-valor {
  display: flex;
  justify-content: flex-start;
  color: #2091C9 !important;
  font-family: 'Barlow' !important;
  font-weight: 700 !important;
  font-size: 3em !important;
  margin-bottom: 0em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 1em;
}

.text-cents-valor {
  display: flex;
  justify-content: flex-start;
  color: #2091C9 !important;
  font-family: 'Barlow' !important;
  font-weight: 700 !important;
  font-size: 3em !important;
  margin-bottom: 0em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 0.3em;
}

.text-time-valor {
  display: flex;
  justify-content: flex-start;
  color: #2091C9 !important;
  font-family: 'Barlow' !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  margin-bottom: 0em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 4em;
  margin-left: 0.6em;
}

.div-info-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: flex-start;
  padding-bottom: 1em;
}

.div-info-p {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: flex-start;
  padding-bottom: 2em;
}

.div-info-p1 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: flex-start;
  padding-bottom: 0em;
}

.text-p-title {
  color: #FFF !important;
  font-family: 'Barlow' !important;
  font-weight: 600 !important;
  font-size: 2em !important;
  margin-bottom: 0.2em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 1em;
  flex-wrap: wrap;
  width: 7em;
}

.text-p-info {
  color: #FFF !important;
  font-family: 'Barlow' !important;
  font-weight: 400 !important;
  font-size: 1em !important;
  margin-bottom: 0em; /* Ajuste o valor conforme necessário */
  margin-top: 0em;
  line-height: 1em;
  flex-wrap: wrap;
  width: 14em;
}


  .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 12em !important;
    
  }
  .Preço {
    font-family: 'Barlow';
    font-size: 30px;
    text-decoration: 'none';
    margin-top: 30;
    margin-bottom: 0;
    font-weight: 600
  }
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 8rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 8rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  .TitleInfo {
    font-size: 2rem;
  }
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 6em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 4em;
    margin: 0em;
  }
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.5em;
  }
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 1em;
    margin: 0em;
  }
  
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 3em;
    margin: 0.2em;
  }
  
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
  }
  
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
  }
  
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  .img-p0 {
    width: 5em;
    height: 5em;
  }
  .img-p0 {
    width: 15em;
    height: 15em;
  }
  .plan-star {
    margin-top: 3em;
    margin-bottom: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em !important;
    padding-right: 4em !important;
    min-height: 200px;
    background-color: #1A0A3D;
  }
  .plan-full {
    margin-top: 3em;
    margin-bottom: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    min-height: 200px;
    background-color: #0E0326;
  }
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 3em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 4em;
    margin: 0em;
  }
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  .ddd-number {
    color: #1A0A3D;
    font-size: 2em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 1.8em;
  }
  .text-number {
    color: #1A0A3D;
    font-size: 3.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 1.8em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  .icon {
    position: absolute;
    width: 2em;
    left: 2em;

  }
  .whatsapp-number {
    width: 3em;
    position: absolute;
    margin-left: -3.4em;
    margin-top: -1.2em
  }
  .div-title {
    margin-top: -0.5em;
    padding-top: 2em;
  }
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 5em;
    margin-left: 5em;
    margin-right: 5em;
    border-radius: 3em;
  }
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  .button-planos {
    background-color: #000040;
    margin-top: 5em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  .image-planos {
    width: 8em;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 6em;
    padding-right: 6em; 
  }
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2em;
    padding-top: 1em;
    padding-bottom: 1em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
  }
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 15em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 15em ; /* Espaçamento entre a linha e o texto */
}
  .div-logo-plan {
    display: flex;
    margin-top: 1em;
    margin-bottom: 0em;
    border-radius: 5em;
    margin-left: 19em;
    margin-right: 19em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 2em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  .img-logo-plan {
    max-width: 100%; /* Para evitar que as imagens se estiquem */
    width: 8em;
    padding-top: 0em;
    padding-bottom: 0.6em;
    padding-left: 0.5em;
    padding-right: 0.5em; 
    background-color: #FFF;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }
  .img-container {
    position: relative;
  }
  .img-text {
    margin-top: -0.5em;
    background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
    color: #FFF;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3.8em;
    padding-right: 3.8em;
    font-family: 'Barlow';
    font-weight: 800;
    font-size: 12px; /* Tamanho da fonte do texto */
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  .text-plan1 {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan1::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan1::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
}

@media (max-width: 1500px) {
  /* 1500 */
  .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 8em !important;
  }
  /* 1500 */
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 4rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 4rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  /* 1500 */
  .TitleInfo {
    font-size: 1rem;
  }
  /* 1500 */
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 3em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  /* 1500 */
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 2em;
    margin: 0em;
  }
  /* 1500 */
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
  }
  /* 1500 */
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 1em;
    margin: 0em;
  }
  /* 1500 */
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 2em;
    margin: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  /* 1500 */
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
  }
  /* 1500 */
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
  }
  /* 1500 */
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 1500 */
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 1500 */
  .plan-star {
    margin-top: 3em;
    border-radius: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #1A0A3D;
  }
  /* 1500 */
  .plan-full {
    margin-top: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #0E0326;
  }
  /* 1500 */
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.8em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  /* 1500 */
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 2em;
    margin: 0em;
  }
  /* 1500 */
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  /* 1500 */
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  /* 1500 */
  .ddd-number {
    color: #1A0A3D;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 2em;
  }
  /* 1500 */
  .text-number {
    color: #1A0A3D;
    font-size: 2.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 2em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  /* 1500 */
  .whatsapp-number {
    width: 2.5em;
    position: absolute;
    margin-left: -2.8em;
    margin-top: -1em
  }
  /* 1500 */
  .div-title {
    margin-top: -1em;
    padding-top: 1.5em;
  }
  /* 1500 */
  .div-title1 {
    margin-top: -4em;
    padding-top: 1.5em;
  }
  /* 1500 */
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 2em;
  }
  /* 1500 */
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  /* 1500 */
  .button-planos {
    background-color: #000040;
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  /* 1500 */
  .image-planos {
    width: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3em;
    padding-right: 3em; 
  }
  /* 1500 */
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 1500 */
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 1500 */
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
/* 1500 */
  .div-logo-plan {
    display: flex;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 0.8em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  /* 1500 */
  .img-logo-plan {
  max-width: 100%; /* Para evitar que as imagens se estiquem */
  width: 3em;
  padding-top: 0em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  background-color: #FFF;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
/* 1500 */
.img-container {
  position: relative;
  display: inline-block; /* Para que os contêineres de imagem e texto fiquem lado a lado */
}
/* 1500 */
.img-text {
  position: absolute;
  top: 100%; /* Posiciona o texto verticalmente no meio da imagem */
  left: 50%; /* Posiciona o texto horizontalmente no meio da imagem */
  transform: translate(-50%, -50%); /* Move o texto para o centro absoluto */
  background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
  color: #FFF;
  width: 86%; /* Largura igual a 100% do contêiner pai */
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: 'Barlow';
  font-weight: 800;
  font-size: 10px; /* Tamanho da fonte do texto */
  white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
}

@media (max-width: 1000px) {
  /* 1000 */
  .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 8em !important;
  }
  /* 1000 */
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 4rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 4rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  /* 1000 */
  .TitleInfo {
    font-size: 1rem;
  }
  /* 1000 */
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 4.5em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  /* 1000 */
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 3em;
    margin: 0em;
  }
  /* 1000 */
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.2em;
  }
  /* 1000 */
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 1em;
    margin: 0em;
  }
  /* 1000 */
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 2em;
    margin: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  /* 1000 */
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
  }
 /* 1000 */
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
  }
  /* 1000 */
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 1000 */
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 1000 */
  .plan-star {
    margin-top: 3em;
    border-radius: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #1A0A3D;
  }
  /* 1000 */
  .plan-full {
    margin-top: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #0E0326;
  }
  /* 1000 */
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.8em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  /* 1000 */
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 3em;
    margin: 0em;
  }
  /* 1000 */
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  /* 1000 */
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  /* 1000 */
  .ddd-number {
    color: #1A0A3D;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 2em;
  }
  /* 1000 */
  .text-number {
    color: #1A0A3D;
    font-size: 2.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 2em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  /* 1000 */
  .whatsapp-number {
    width: 2.5em;
    position: absolute;
    margin-left: -2.8em;
    margin-top: -1em
  }
  /* 1000 */
  .div-title {
    margin-top: -1em;
    padding-top: 1.5em;
  }
  /* 1000 */
  .div-title1 {
    margin-top: -4em;
    padding-top: 1.5em;
  }
  /* 1000 */
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 2em;
  }
  /* 1000 */
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  /* 1000 */
  .button-planos {
    background-color: #000040;
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  /* 1000 */
  .image-planos {
    width: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3em;
    padding-right: 3em; 
  }
  /* 1000 */
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 1000 */
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 1000 */
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
/* 1000 */
  .div-logo-plan {
    display: flex;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 0.8em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  /* 1000 */
  .img-logo-plan {
  max-width: 100%; /* Para evitar que as imagens se estiquem */
  width: 3em;
  padding-top: 0em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  background-color: #FFF;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
/* 1000 */
.img-container {
  position: relative;
  display: inline-block; /* Para que os contêineres de imagem e texto fiquem lado a lado */
}
/* 1000 */
.img-text {
  position: absolute;
  top: 100%; /* Posiciona o texto verticalmente no meio da imagem */
  left: 50%; /* Posiciona o texto horizontalmente no meio da imagem */
  transform: translate(-50%, -50%); /* Move o texto para o centro absoluto */
  background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
  color: #FFF;
  width: 86%; /* Largura igual a 100% do contêiner pai */
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: 'Barlow';
  font-weight: 800;
  font-size: 10px; /* Tamanho da fonte do texto */
  white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
/* 480 */
.MuiPopover-paper.MuiMenu-paper .MuiTypography-root {
  background-color: transparent !important;
}
/* 480 */
.MuiPopover-paper.MuiMenu-paper {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
/* 480 */
.text-typography {
  color: #FFF;
  font-family: 'Barlow' !important;
  font-weight: 600 !important;
  font-size: 1em !important;
}
}

@media (max-width: 768px) {
  /* 768 */
  .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 8em !important;
  }
  /* 768 */
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 4rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 4rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  /* 768 */
  .TitleInfo {
    font-size: 1rem;
  }
  /* 768 */
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 3em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  /* 768 */
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 2em;
    margin: 0em;
  }
  /* 768 */
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
  }
  /* 768 */
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 1em;
    margin: 0em;
  }
  /* 768 */
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 2em;
    margin: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  /* 768 */
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
  }
 /* 768 */
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
  }
  /* 768 */
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 768 */
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 768 */
  .plan-star {
    margin-top: 3em;
    border-radius: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #1A0A3D;
  }
  /* 768 */
  .plan-full {
    margin-top: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #0E0326;
  }
  /* 768 */
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.8em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  /* 768 */
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 2em;
    margin: 0em;
  }
  /* 768 */
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  /* 768 */
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  /* 768 */
  .ddd-number {
    color: #1A0A3D;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 2em;
  }
  /* 768 */
  .text-number {
    color: #1A0A3D;
    font-size: 2.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 2em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  /* 768 */
  .whatsapp-number {
    width: 2.5em;
    position: absolute;
    margin-left: -2.8em;
    margin-top: -1em
  }
  /* 768 */
  .div-title {
    margin-top: -1em;
    padding-top: 1.5em;
  }
  /* 768 */
  .div-title1 {
    margin-top: -4em;
    padding-top: 1.5em;
  }
  /* 768 */
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 2em;
  }
  /* 768 */
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  /* 768 */
  .button-planos {
    background-color: #000040;
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  /* 768 */
  .image-planos {
    width: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3em;
    padding-right: 3em; 
  }
  /* 768 */
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 768 */
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 768 */
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
/* 768 */
  .div-logo-plan {
    display: flex;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 0.8em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  /* 768 */
  .img-logo-plan {
  max-width: 100%; /* Para evitar que as imagens se estiquem */
  width: 3em;
  padding-top: 0em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  background-color: #FFF;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
/* 768 */
.img-container {
  position: relative;
  display: inline-block; /* Para que os contêineres de imagem e texto fiquem lado a lado */
}
/* 768 */
.img-text {
  position: absolute;
  top: 100%; /* Posiciona o texto verticalmente no meio da imagem */
  left: 50%; /* Posiciona o texto horizontalmente no meio da imagem */
  transform: translate(-50%, -50%); /* Move o texto para o centro absoluto */
  background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
  color: #FFF;
  width: 86%; /* Largura igual a 100% do contêiner pai */
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: 'Barlow';
  font-weight: 800;
  font-size: 10px; /* Tamanho da fonte do texto */
  white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
}

@media (max-width: 540px) {
   /* 540 */
   .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 8em !important;
  }
  /* 540 */
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 4rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 4rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  /* 540 */
  .TitleInfo {
    font-size: 1rem;
  }
  /* 540 */
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 3em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  /* 540 */
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 2em;
    margin: 0em;
  }
  /* 540 */
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
  }
  /* 540 */
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 1em;
    margin: 0em;
  }
  /* 540 */
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 2em;
    margin: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  /* 540 */
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
  }
 /* 540 */
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
  }
  /* 540 */
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 540 */
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 540 */
  .plan-star {
    margin-top: 3em;
    border-radius: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #1A0A3D;
  }
  /* 540 */
  .plan-full {
    margin-top: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #0E0326;
  }
  /* 540 */
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.8em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  /* 540 */
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 2em;
    margin: 0em;
  }
  /* 540 */
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  /* 540 */
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  /* 540 */
  .ddd-number {
    color: #1A0A3D;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 2em;
  }
  /* 540 */
  .text-number {
    color: #1A0A3D;
    font-size: 2.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 2em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  /* 540 */
  .whatsapp-number {
    width: 2.5em;
    position: absolute;
    margin-left: -2.8em;
    margin-top: -1em
  }
  /* 540 */
  .div-title {
    margin-top: -1em;
    padding-top: 1.5em;
  }
  /* 540 */
  .div-title1 {
    margin-top: -4em;
    padding-top: 1.5em;
  }
  /* 540 */
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 2em;
  }
  /* 540 */
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  /* 540 */
  .button-planos {
    background-color: #000040;
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  /* 540 */
  .image-planos {
    width: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3em;
    padding-right: 3em; 
  }
  /* 540 */
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 540 */
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 540 */
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
/* 540 */
  .div-logo-plan {
    display: flex;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 0.8em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  /* 540 */
  .img-logo-plan {
  max-width: 100%; /* Para evitar que as imagens se estiquem */
  width: 3em;
  padding-top: 0em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  background-color: #FFF;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
/* 540 */
.img-container {
  position: relative;
  display: inline-block; /* Para que os contêineres de imagem e texto fiquem lado a lado */
}
/* 540 */
.img-text {
  position: absolute;
  top: 100%; /* Posiciona o texto verticalmente no meio da imagem */
  left: 50%; /* Posiciona o texto horizontalmente no meio da imagem */
  transform: translate(-50%, -50%); /* Move o texto para o centro absoluto */
  background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
  color: #FFF;
  width: 86%; /* Largura igual a 100% do contêiner pai */
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: 'Barlow';
  font-weight: 800;
  font-size: 10px; /* Tamanho da fonte do texto */
  white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
}



@media (max-width: 480px) {
  .Preço {
    font-family: 'Barlow';
    font-size: 24px;
    text-decoration: 'none';
    margin-top: 30;
    margin-bottom: 0;
    font-weight: 600;
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  }
  /* 480 */
  .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 8em !important;
  }
  /* 480 */
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 4rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 4rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  /* 480 */
  .TitleInfo {
    font-size: 1rem;
  }
  /* 480 */
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 3em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  /* 480 */
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 2em;
    margin: 0em;
  }
  /* 480 */
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
  }
  /* 480 */
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 0.8em;
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
    text-align: center;
  }
  /* 480 */
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 2em;
    margin: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  }
  /* 480 */
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  }
  /* 480 */
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  }
  /* 480 */
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
    width: 100%;
  }
  /* 480 */
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
    width: 100%;
  }
  /* 480 */
  .img-p0 {
    width: 9em;
    height: 9em;
  }
  /* 480 */
  .plan-star {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    border-radius: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #1A0A3D;
    width: 7em;
  }
  /* 480 */
  .plan-full {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #0E0326;
    width: 7em;
  }
  /* 480 */
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.8em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  /* 480 */
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 2em;
    margin: 0em;
  }
  /* 480 */
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  /* 480 */
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  /* 480 */
  .ddd-number {
    color: #1A0A3D;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 2em;
  }
  /* 480 */
  .text-number {
    color: #1A0A3D;
    font-size: 2.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 2em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  /* 480 */
  .whatsapp-number {
    width: 2.5em;
    position: absolute;
    margin-left: -2.8em;
    margin-top: -1em
  }
  /* 480 */
  .div-title {
    margin-top: -1em;
    padding-top: 1.5em;
  }
  /* 480 */
  .div-title1 {
    margin-top: -4em;
    padding-top: 1.5em;
    margin-left: 1em;
    margin-right: 1em;
  }
  /* 480 */
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 2em;
  }
  /* 480 */
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  /* 480 */
  .button-planos {
    background-color: #000040;
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  /* 480 */
  .image-planos {
    width: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3em;
    padding-right: 3em; 
  }
  /* 480 */
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 480 */
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 480 */
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
/* 480 */
  .div-logo-plan {
    display: flex;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 0.8em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  /* 480 */
  .img-logo-plan {
  max-width: 100%; /* Para evitar que as imagens se estiquem */
  width: 3em;
  padding-top: 0em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  background-color: #FFF;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
/* 480 */
.img-container {
  position: relative;
  display: inline-block; /* Para que os contêineres de imagem e texto fiquem lado a lado */
}
/* 480 */
.img-text {
  position: absolute;
  top: 100%; /* Posiciona o texto verticalmente no meio da imagem */
  left: 50%; /* Posiciona o texto horizontalmente no meio da imagem */
  transform: translate(-50%, -50%); /* Move o texto para o centro absoluto */
  background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
  color: #FFF;
  width: 86%; /* Largura igual a 100% do contêiner pai */
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: 'Barlow';
  font-weight: 800;
  font-size: 10px; /* Tamanho da fonte do texto */
  white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
/* 480 */
.MuiPopover-paper.MuiMenu-paper .MuiTypography-root {
  background-color: transparent !important;
}
/* 480 */
.MuiPopover-paper.MuiMenu-paper {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
/* 480 */
.text-typography {
  color: #FFF;
  font-family: 'Barlow' !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
}


}

@media (max-width: 280px) {
  /* 280 */
  .StyledChannels {
    margin-top: 4rem;
    border-radius: 15px;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center;
    width: 100% !important;
    height: 8em !important;
  }
  /* 280 */
  .Imagem {
    border-radius: 15%; /* Definindo o valor como 50% faz com que a imagem fique totalmente arredondada. */
    width: 4rem; /* Defina o tamanho da imagem conforme necessário. */
    height: 4rem;
    justify-content: center; /* Centraliza horizontalmente no eixo principal (horizontal). */
    align-items: center; /* Centraliza verticalmente no eixo cruzado (vertical). */
    background: #FFF;
  }
  /* 280 */
  .TitleInfo {
    font-size: 1rem;
  }
  /* 280 */
  .text-title {
    color: #2091C9 !important;
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 3em !important;
    margin-bottom: -0.2em; /* Ajuste o valor conforme necessário */
  }
  /* 280 */
  .text-sub {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 2em;
    margin: 0em;
  }
  /* 280 */
  .text-p {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 0.8em;
  }
  /* 280 */
  .text-p1 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 300;
    font-size: 1em;
    margin: 0em;
  }
  /* 280 */
  .text-p2 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 2em;
    margin: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  /* 280 */
  .text-p3 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1em;
    margin: 0.2em;
    margin-bottom: 1.5em;
  }
  /* 280 */
  .text-p4 {
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.2em;
  }
  /* 280 */
  .text-p5 {
    color: #1A0A3D;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 280 */
  .text-p5-full {
    color: #0E0326;
    font-family: 'Barlow';
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    margin-left: 2.8em;
    margin-right: 2.8em;
  }
  /* 280 */
  .plan-star {
    margin-top: 3em;
    border-radius: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #1A0A3D;
  }
  /* 280 */
  .plan-full {
    margin-top: 3em;
    border-radius: 2em;
    padding-bottom: 2em;
    padding-left: 4em;
    padding-right: 4em;
    min-height: 200px;
    background-color: #0E0326;
  }
  /* 280 */
  .p-assine {
    color: #000040;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.8em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #FDEB1A, #FDEB1A );
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-decoration: none; /* Remove o sublinhado */
  }
  /* 280 */
  .text-title1 {
    color: #2091C9;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 2em;
    margin: 0em;
  }
  /* 280 */
  .contato {
    margin-top: 4em;
    padding-bottom: 0.5em;
  }
  /* 280 */
  .pcontato {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 600;
    padding-top: 0.5em;
    margin: 0em;
  }
  /* 280 */
  .ddd-number {
    color: #1A0A3D;
    font-size: 1.5em;
    font-family: 'Barlow';
    font-weight: 700;
    padding-left: 38;
    border-radius: 22;
    text-decoration: none;
    position: absolute;
    margin-top: 0.8em;
    margin-left: 2em;
  }
  /* 280 */
  .text-number {
    color: #1A0A3D;
    font-size: 2.5em;
    font-family: 'Barlow';
    font-weight: 500;
    padding-left: 2em;
    padding-right: 0.8em;
    padding-top: 0em;
    border-radius: 1em;
    background: #FDEB1A;
    text-decoration: none;
  }
  /* 280 */
  .whatsapp-number {
    width: 2.5em;
    position: absolute;
    margin-left: -2.8em;
    margin-top: -1em
  }
  /* 280 */
  .div-title {
    margin-top: -1em;
    padding-top: 1.5em;
  }
  /* 280 */
  .div-title1 {
    margin-top: -4em;
    padding-top: 1.5em;
  }
  /* 280 */
  .div-full {
    background-color: #0E0326;
    padding-bottom: 5em;
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 2em;
  }
  /* 280 */
  .div-planos {
    display: flex;
    align-items: center;
    justify-content: space-evenly; 
  }
  /* 280 */
  .button-planos {
    background-color: #000040;
    margin-top: 3em;
    margin-bottom: 2em;
    border-radius: 5em;
  }
  /* 280 */
  .image-planos {
    width: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 3em;
    padding-right: 3em; 
  }
  /* 280 */
  .text-plan {
    display: flex;
    color: #FFF;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0.2em;
    align-items: center;
    line-height: 1; /* Ajuste o valor conforme necessário para diminuir a altura das linhas */
    justify-content: center;
    flex-wrap: wrap;
  }
  /* 280 */
  .text-plan::before {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1.5em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1em ; /* Espaçamento entre a linha e o texto */
  }
  /* 280 */
  .text-plan::after {
    content: "";
    flex-grow: 0.5;
    height: 0.05em;
    background-color: #FFF;
    margin-left: 1em ; /* Espaçamento entre a linha e o texto */
    margin-right: 1.5em ; /* Espaçamento entre a linha e o texto */
}
/* 280 */
  .div-logo-plan {
    display: flex;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-radius: 5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex-wrap: wrap; /* Isso permite que os itens quebrem em uma nova linha */
    gap: 0.8em; /* Espaçamento entre as imagens (opcional) */
    justify-content: flex-start; 
  }
  /* 280 */
  .img-logo-plan {
  max-width: 100%; /* Para evitar que as imagens se estiquem */
  width: 3em;
  padding-top: 0em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  background-color: #FFF;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
/* 280 */
.img-container {
  position: relative;
  display: inline-block; /* Para que os contêineres de imagem e texto fiquem lado a lado */
}
/* 280 */
.img-text {
  position: absolute;
  top: 100%; /* Posiciona o texto verticalmente no meio da imagem */
  left: 50%; /* Posiciona o texto horizontalmente no meio da imagem */
  transform: translate(-50%, -50%); /* Move o texto para o centro absoluto */
  background-color: #1A0A3D; /* Fundo semi-transparente para o texto */
  color: #FFF;
  width: 86%; /* Largura igual a 100% do contêiner pai */
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: 'Barlow';
  font-weight: 800;
  font-size: 10px; /* Tamanho da fonte do texto */
  white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
}












